import { AppBar, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Image from "next/image";
import React from "react";

import Logo from "@images/navLogoLeinwand.webp";

import styles from "../styles/layout/navbar.module.scss";
import { useRouter } from "next/router";
import { Routes } from "../helperFunctions/routes";
import Link from "next/link";

export default function Navbar() {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkActiveRoute = (pathToCheck: string) => {
    return router.pathname === pathToCheck ? "active" : "";
  };

  return (
    <div className={styles["app-bar-toolbar"]}>
      <AppBar position={"sticky"}>
        <Toolbar className={styles["app-bar-toolbar-root"]}>
          <Link
            href={Routes.Startseite}
            passHref
            title="Musliu Reinigung Hamburg"
          >
            <Image
              src={Logo}
              title="Musliu Reinigung Hamburg"
              alt="Musliu Reinigung Hamburg"
              height={64}
              width={200}
              style={{ objectFit: "scale-down" }}
            />
          </Link>
          <div className={styles["app-bar-menu-mobile"]}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              className={styles["menu-item-container"]}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link href={Routes.Startseite} passHref title="Startseite">
                  Startseite
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  href={Routes.Dienstleistungen}
                  passHref
                  title="Dienstleistung"
                >
                  Dienstleistung
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href={Routes.Kontakt} passHref title="Kontakt">
                  Kontakt
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href={Routes.UeberUns} passHref title="Über uns">
                  Über uns
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href={Routes.Impressum} passHref title="Impressum">
                  Impressum
                </Link>
              </MenuItem>
            </Menu>
          </div>
          <div className={styles["app-bar-menu-desktop"]}>
            <Link
              className={styles[checkActiveRoute(Routes.Startseite)]}
              href={Routes.Startseite}
              passHref
              title="Startseite"
            >
              Startseite
            </Link>
            <Link
              className={styles[checkActiveRoute(Routes.Dienstleistungen)]}
              href={Routes.Dienstleistungen}
              passHref
              title="Dienstleistungen"
            >
              Dienstleistungen
            </Link>
            <Link
              className={styles[checkActiveRoute(Routes.Kontakt)]}
              href={Routes.Kontakt}
              passHref
              title="Kontakt"
            >
              Kontakt
            </Link>
            <Link
              className={styles[checkActiveRoute(Routes.UeberUns)]}
              href={Routes.UeberUns}
              passHref
              title="Über uns"
            >
              Über uns
            </Link>
            <Link
              className={styles[checkActiveRoute(Routes.Impressum)]}
              href={Routes.Impressum}
              passHref
              title="Impressum"
            >
              Impressum
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
